var gaProperty = 'UA-157808814-1';
var disableStr = 'ga-disable-' + gaProperty;

if (document.cookie.indexOf(disableStr + '=true') > -1) {
  window[disableStr] = true;
}

// Event snippet for Anfrage Softwareentwicklung
function gtag_report_send(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  gtag('event', 'conversion', {
      'send_to': 'AW-665331981/fy8mCO_5l8cBEI3SoL0C',
      'event_callback': callback
  });
  return false;
}

// Event snippet for Button Klick Kontakt (also modals)
function gtag_report_open_contact_form(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  gtag('event', 'conversion', {
      'send_to': 'AW-665331981/OqadCK2o9f0CEI3SoL0C',
      'event_callback': callback
  });
  return false;
}

$(document).ready(function() {
  $('#gaOptoutLink').on('click',
    function() {
      gaOptout();
      return false;
    }
  );

  $('[id=report-submit-action]').on('click', function() {
    gtag_report_send(undefined)
  });

  $('[id=report-open-contact-form-action]').on('click', function() {
    gtag_report_open_contact_form(undefined)
  });
});

function gaOptout() {
  document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/; SameSite=none; Secure';
  window[disableStr] = true;
  window.alert("Google Analytics ist jetzt deaktiviert");
};
