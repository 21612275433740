klaro = require('klaro')
window.klaro = klaro

Watcher = class Watcher {
  update(obj, name, data) {
    if (name == "applyConsents") {
      location.reload()
    }
  }
}

klaro.getManager().watch(new Watcher())
