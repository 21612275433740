$(document).ready(function () {
    function animateValue(id, start, duration) {
        // assumes integer values for start and end
        var obj = document.getElementById(id);
        if (!obj) return;

        var end = obj.getAttribute('data-target');
        var range = end - start;
        // no timer shorter than 50ms (not really visible any way)
        var minTimer = 50;
        // calc step time to show all interediate values
        var stepTime = Math.abs(Math.floor(duration / range));

        // never go below minTimer
        stepTime = Math.max(stepTime, minTimer);

        // get current time and calculate desired end time
        var startTime = new Date().getTime();
        var endTime = startTime + duration;
        var timer;

        function run() {
            var now = new Date().getTime();
            var remaining = Math.max((endTime - now) / duration, 0);
            var value = Math.round(end - (remaining * range));
            obj.innerHTML = value;
            if (value == end) {
                clearInterval(timer);
            }
        }

        timer = setInterval(run, stepTime);
        run();
    }
    animateValue("projects", 0, 2500);
    animateValue("contributors", 0, 2500);
    animateValue("customers", 0, 2500);
    animateValue("years", 0, 2500);
});
