import $ from 'jquery'
import 'popper.js'
import 'bootstrap/dist/js/bootstrap'
import 'slick-carousel'
import '../src/shared/klaro_config.js'
import 'klaro'

window.jQuery = $
window.$ = $

require.context("../images", true)
const imagePath = (name) => images(name, true)
let req = require.context("../src/application", true)
req.keys().forEach(req)
