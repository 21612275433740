$(document).ready(function () {
  var rand;
  $('.job_statements .additional-content').not('.slick-initialized').slick({
    dots: true,
    dotsClass: 'slick-dots',
    draggable: false,
    lazyLoad: 'ondemand',
    swipe: true,
    centerMode: false,
    centerPadding: '60px',
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    nextArrow: '<i class="button-next"></i>',
    prevArrow: '<i class="button-prev"></i>'
  });
  rand = function () {
    var imageAmount;
    imageAmount = $('.job_statements .additional-content .slick-slide').length - $('.job_statements .additional-content .slick-cloned').length;
    rand = Math.floor(Math.random() * imageAmount);
    return parseInt(rand);
  };
  return $('.job_statements .additional-content').slick('slickGoTo', rand());
});
