$(document).ready(function () {
  return $('.image_carousel .additional-content').not('.slick-initialized').slick({
    dots: true,
    dotsClass: 'slick-dots',
    draggable: false,
    lazyLoad: 'ondemand',
    swipe: true,
    centerMode: false,
    centerPadding: '60px',
    speed: 2500,
    slidesToShow: 1,
    autoplaySpeed: 10000,
    autoplay: true,
    adaptiveHeight: true,
    nextArrow: '<i class="button-next"></i>',
    prevArrow: '<i class="button-prev"></i>'
  });
});
