$(document).ready(function() {

    $('[id=report-submit-action-phone]').on('click', function() {
        uet_report_conversion
    });
    
    $('[id=report-submit-action-e-mail]').on('click', function() {
        uet_report_conversion1
    });
    
    $('[id=report-open-contact-form-action]').on('click', function() {
        uet_report_conversion2
    });
    
    });
    
    