window.klaroConfig = {
  elementID: 'conlanceKlaro',
  lang: 'de',
  cookieName: 'conlance_cookie_consent',

  storageMethod: 'cookie',

  storageName: 'conlance_cookie_consent',

  cookieExpiresAfterDays: 28,

  privacyPolicy: '/datenschutz',

  mustConsent: true,

  acceptAll: true,

  hideDeclineAll: false,

  groupByPurpose: false,

  hideToggleAll: true,

  translations: {
    zz: {
      privacyPolicyUrl: '/datenschutz',
    },
    de: {
      ok: 'Alle akzeptieren',
      acceptAll: 'Alle akzeptieren',
      acceptSelected: 'Auswahl speichern',
      decline: 'Alle ablehnen',
      consentModal: {
        title: 'Dürfen wir Ihnen Cookies anbieten?',
        description:
          'Neben tollen Features helfen uns Cookies auch dabei unser Angebot stetig zu verbessern. Wir setzen Cookies für folgende Zwecke ein: Marketing, Statistik, Layout & Essenziell.' +
          ' Hier können Sie einsehen und anpassen, welche Cookies wir verwenden. Manche Widgets funktionieren ohne Cookies nicht, daher ist es ratsam die Cookies anzunehmen',
      },
      google_tag_manager: {
        description: 'Tag-Management-System zum Verwalten von Marketing Tools.',
      },
      google_analytics: {
        description: 'Sammeln von anonymen Nutzungsstatistiken. Die erfassten Daten helfen uns dabei unser Angebot zu verbessern.',
      },
      provenexpert: {
        description: 'Ein externes Widget zum Anzeigen von Kundenbewertungen. Das Deaktivieren dieser Einstellung kann zu Darstellungsfehlern führen.',
      },
      // Disable Tidio for now, remove after tidio account ist cancelled
      // tidio: {
      //   description:
      //     'Live Chat Tool mit dem Sie direkt mit uns in Kontakt treten können.',
      // },
      microsoft_uet: {
        description: 'Sammeln von anonymen Nutzungsstatistiken. Die erfassten Daten helfen uns dabei unser Angebot zu verbessern.'
      },
      essenziell: {
        description:
          'Diese Cookies werden zum richtigen Ausführen der Website benötigt.',
      },
      purposes: {
        analytics: 'Statistik',
        // Disable Tidio for now, remove after tidio account ist cancelled
        // livechat: 'Live Chat',
        layout: 'Layout',
        functional: 'Funktional',
        essenziell: 'Essenziell',
      },
    },
  },

  services: [
    {
      name : 'google_tag_manager',
      title : 'Google Tag Manager',
      purposes : ['marketing'],
      optOut: false,
    },
    {
      name : 'google_analytics',
      title : 'Google Analytics',
      purposes : ['analytics'],
      cookies : [
        'DSID', 'IDE', 'SIDCC', 'ANID', '1P_JAR', 'NID',
        'CONSENT', 'APISID', '__Secure-HSID', 'SEARCH_SAMESITE',
        '__Secure-3PAPISID', '__Secure-APISID', '__Secure-SSID',
        'SAPISID', 'SSID', 'HSID', '__Secure-3PSID', 'SID',
        '_gid', '_gat_gtag_UA_157808814_1', '_ga'
      ],
      callback : function(consent){
        var gaProperty = 'UA-157808814-1';
        var disableStr = 'ga-disable-' + gaProperty;
        if(consent){
          document.cookie = disableStr + '=; expires=Thu, 31 Dec 1920 23:59:59 UTC; path=/; SameSite=none; Secure';
        } else {
          document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/; SameSite=none; Secure';
          window[disableStr] = true;
        }
      },
      optOut: false,
    },
    {
      name : 'provenexpert',
      title : 'Proven Expert',
      purposes : ['functional', 'layout'],
      cookies : [
        'registerlocale', 'PE_SESSION'
      ],
      optOut: false,
    },
    {
      name : 'microsoft_uet',
      title : 'Microsoft Advertising',
      purposes : ['analytics'],
      cookies : [
        'MUID', '_uetmsclkid', '_uetsid', '_uetvid'
      ],
      optOut: false,
    },

    // Disable Tidio for now, remove after tidio account ist cancelled
    // {
    //   name: 'tidio',
    //   title: 'Tidio',
    //   purposes: ['functional', 'livechat'],
    //   cookies : [
    //     '__cfduid'
    //   ],
    //   optOut: false,
    // },
    
    {
      name: 'essenziell',
      title: 'Essenzielle Cookies',
      default: true,
      purposes: ['essenziell'],
      required: true,
    },
  ],
}
if (window.location.href.indexOf("datenschutz") > -1) {
  klaroConfig["mustConsent"] = false;
}
